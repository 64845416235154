import { egiftCatalogProductService } from "@services";
import { formatDate } from "@utils";
import { mapState } from 'vuex';
import { LANGUAGES } from "@i18n";

// components
import {
    ScreenLoading,
    EgiftCatalogProductBox as ProductBox,
    EgiftCatalogSelectedProductModal as SelectedProductModal,
} from "@components";
import HeaderLanguages from "./components/Layouts/HeaderLanguages/HeaderLanguages.vue";

export default {
    name: "EgiftCatalog",
    components: {
        ScreenLoading,
        ProductBox,
        SelectedProductModal,
        HeaderLanguages,
    },
    data() {
        return {
           selectedModalId: "selected-product-modal",
           isFetchingPage: true,
           products: [],
           selectedProduct: {},
        }
    },
    methods: {
        // fetch products list
        loadProducts: async function () {
            let egiftCatalogUUID = this.$route.params.uuid;

            const result = await egiftCatalogProductService.index(
                egiftCatalogUUID,
                {
                    language: this.selectedLanguageId,
                }
            );

            if (result && result?.data) {
                this.products = result.data;

                // Start render
                this.isFetchingPage = false;
            } else {
                // Redirect to page 404 error
                this.$router.push({ path: '/error-404' }).catch(() => {});
            }
        },
        // emit - open select product modal
        showProduct: function (product) {
            this.selectedProduct = product;
            this.$bvModal.show(this.selectedModalId);
        },
        // emit - exchange product fail - limit reached
        updateProductLimitReached: function(product) {
            this.products = this.products.map((item) => {
                if (item.id === product.id) {
                    item.exchanged_count = item.limit_reached;
                }
                return item;
            })
        }
    },
    computed: {
        expiryDate: function () {
            return formatDate(this.egiftCatalog.expired_at);
        },
        isExpired: function () {
            return this.egiftCatalog.is_expired;
        },
        ...mapState('egiftCatalog', ['egiftCatalog', 'selectedLanguageId']),
    },
    created() {
        this.$i18n.locale = LANGUAGES[this.selectedLanguageId];

        // fetch data
        this.loadProducts();
    }
}
