import { IMAGES } from "@assets";
import { mapState } from 'vuex';

// components
import VueLoadImage from "vue-load-image";
import { BaseHeader } from "@components";

export default {
    name: "EgiftCatalogHeaderLanguages",
    components: {
        "vue-load-image": VueLoadImage,
        BaseHeader,
    },
    data() {
        return {
            IMAGES,
            logoFileName: null,
        };
    },
    computed: {
        // get egift catalog logo or default logo
        egiftCatalogLogo: function () {
            return this.egiftCatalog?.catalog?.full_path_logo_image;
        },
        ...mapState('egiftCatalog', ['egiftCatalog', 'languages', 'selectedLanguageId']),
    },
    methods: {
        // event when change select language
        onChangeLanguage: function (e) {
            this.$store.commit('egiftCatalog/setSelectedLanguage', e.target.value);
            this.$router.go();
        },
    },
};
