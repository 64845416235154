import { render, staticRenderFns } from "./EgiftCatalog.html?vue&type=template&id=ee2f3e26&scoped=true&"
import script from "./EgiftCatalog.js?vue&type=script&lang=js&"
export * from "./EgiftCatalog.js?vue&type=script&lang=js&"
import style0 from "./EgiftCatalog.scss?vue&type=style&index=0&id=ee2f3e26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee2f3e26",
  null
  
)

export default component.exports